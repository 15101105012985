import { DebugLevel, appLog } from "../functions/Logging";
import { inRange } from "../utils/GeneralUtils";
import FEASTS from "../data/feasts.json";
import { FeastFastTimeType } from "../enums/FeastAndFastType";
import { getWeekDayOfCopticDate } from "../utils/CopticDateUtils";

const getAllNonMovableFeast = () => {
  return FEASTS.filter((feast) => feast.time_type == FeastFastTimeType.NON_MOVABLE.name);
};

const getAllMovableFeast = () => {
  return FEASTS.filter((feast) => feast.time_type == FeastFastTimeType.MOVABLE.name);
};

const getAllMonthlyFeast = () => {
  return FEASTS.filter((feast) => feast.time_type == FeastFastTimeType.MONTHLY.name);
};

const getFeastByKey = (key) => {
  return FEASTS.filter((feast) => feast.key == key)[0];
};

const getMonthlyFeastByKey = (key) => {
  return getAllMonthlyFeast().filter((feast) => feast.key == key);
};

const matchMovable = (daysToEastern) => {
  return FEASTS_MOVABLE.filter((feast) =>
    inRange(feast.times.daysToEasternStart, feast.times.daysToEasternEnd, daysToEastern)
  );
};

const matchMovableStart = (daysToEastern, month, day) => {
  let feastWithMovableStart = FEASTS_MOVABLE.filter(
    (feast) =>
      feast.times.daysToEasternStart != undefined &&
      Math.abs(daysToEastern) >= Math.abs(feast.times.daysToEasternStart) &&
      month == feast.times.monthEnd &&
      day <= feast.times.dayEnd
  );
  return feastWithMovableStart;
};

const matchNonMovable = (month, day) => {
  return FEASTS_NONMOVABLE.filter(
    (feast) =>
      day >= feast.times.dayStart &&
      day <= feast.times.dayEnd &&
      month >= feast.times.monthStart &&
      month <= feast.times.monthEnd
  );
};

const matchMonthly = (day, month) => {
  return FEASTS_MONTHLY.filter(
    (feast) => feast.times.monthlyDay == day && feast.times.exceptionMonth.indexOf(eval(month)) < 0
  );
};

appLog(DebugLevel.DEBUG, "[FeastService]", "Start");
const FEASTS_NONMOVABLE = getAllNonMovableFeast();
const FEASTS_MOVABLE = getAllMovableFeast();
const FEASTS_MONTHLY = getAllMonthlyFeast();
appLog(DebugLevel.DEBUG, "[FeastService]", "End");

/**
 * currently only works with non-movable feasts
 *
 * @param {*} cYear
 * @param {*} copticFeast
 * @returns
 */
function getWeekDayOfCopticFeast(cYear, copticFeast) {
  const feast = getFeastByKey(copticFeast);
  const cMonth = feast.times.monthStart;
  const cDay = feast.times.dayStart;
  return getWeekDayOfCopticDate({ y: cYear, m: cMonth, d: cDay });
}

/**
 *
 * @param {*} year coptic format
 * @param {*} month coptic format
 * @param {*} day coptic format
 * @returns
 */
const getFeasts = function (cDate, daysToEastern) {
  const year = cDate.y;
  const month = cDate.m;
  const day = cDate.d;
  appLog(DebugLevel.DEBUG, "[FeastsService]", `getFeasts for ${year}-${month}-${day}`);

  // filter for non-movable
  const nonMovable = matchNonMovable(month, day);

  // filter for movable
  const movable = matchMovable(daysToEastern);
  const movable2 = matchMovableStart(daysToEastern, month, day);

  // filter for monthly
  const monthly = matchMonthly(day, month);

  // join
  let feasts = nonMovable.concat(movable, movable2, monthly);

  return feasts;
};

export { getFeasts, getMonthlyFeastByKey, FEASTS, getFeastByKey, getWeekDayOfCopticFeast };
