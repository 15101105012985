<script setup>
import { computed } from "vue";
import { defineProps } from "vue";
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList } from "@ionic/vue";
// Import the StoreItem component
import VarusPiniaStoreItem from "./VarusPiniaStoreItem.vue";

// Define the props the component accepts
const props = defineProps({
  store: {
    type: Object,
    required: true,
  },
  storeId: {
    type: String,
    required: true,
  },
});

// Extract the store's state
const storeState = computed(() => props.store.$state);

// Optionally, get the store's name if available
const storeName = computed(() => props.store.$id || "Unnamed Store");
</script>

<template>
  <ion-card :id="storeId">
    <ion-card-header>
      <ion-card-title>Store State: {{ storeName }}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-list>
        <VarusPiniaStoreItem v-for="(value, key) in storeState" :key="key" :label="key" :value="value" />
      </ion-list>
    </ion-card-content>
  </ion-card>
</template>

<style scoped>
ion-card {
  margin: 0.1rem;
}
</style>
