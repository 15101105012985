<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "App.vue",
};
</script>
<script setup>
import {
  IonApp,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonRouterOutlet,
  IonHeader,
  IonToolbar,
  IonSplitPane,
  menuController,
} from "@ionic/vue";
import { onBeforeMount, getCurrentInstance, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { DebugLevel, appLog } from "./functions/Logging";
import { useRoute } from "vue-router";

// Varus Components
import VarusMenuLeftSide from "./components/VarusMenuLeftSide.vue";
import VarusTableOfPrayers from "./components/VarusTableOfPrayers.vue";
import VarusPinia from "./components/VarusPinia.vue";
import VarusChurchSeasonDynamic from "./components/VarusChurchSeasonDynamic.vue";

// Pinia Stores
import { useUserSettingsStore } from "./stores/userSettingsStore";
import { useScrollStore } from "./stores/scrollStore.js";
const scrollStore = useScrollStore();

// i18n
const { locale } = useI18n({ useScope: "global" });

// Logging
const compName = "[" + getCurrentInstance().type.name + "]";

// Dev Mode?
const isDevelopment = process.env.NODE_ENV === "development";

/**
 * called, when the component has finished setting up its reactive state,
 * but no DOM nodes have been created yet.
 * It is about to execute its DOM render effect for the first time.
 */
onBeforeMount(() => {
  // set locale
  locale.value = useUserSettingsStore().uiLanguage.key;
  appLog(DebugLevel.INFO, compName, "Setting locale to: " + locale.value);
});

const scrollToPrayerMenu = async (id) => {
  nextTick(async () => {
    appLog(DebugLevel.SCROLL, compName, "id: ", id);
    const menuItem = document.getElementById(`menu-item-${id}`);
    if (menuItem) {
      // Find the Scrollable Container:
      // Ionic components use Shadow DOM internally. To access the scrollable container inside ion-content, the code queries its shadowRoot.
      const scrollContainer = menuItem.closest("ion-content")?.shadowRoot?.querySelector(".inner-scroll");
      if (scrollContainer) {
        scrollContainer.scrollTo({
          top: menuItem.offsetTop - 50,
          behavior: "smooth",
        });
      }
    }
  });
};

// Event triggered after the right-menu is open
const onRightMenuOpened = () => {
  scrollToPrayerMenu(scrollStore.scrollElementMain);
};
</script>

<template>
  <IonApp>
    <ion-split-pane when="xl" content-id="main-content">
      <!-- LEFT MENU - Start -->
      <ion-menu
        menuId="left-menu"
        side="start"
        content-id="main-content"
        type="overlay"
        @click="menuController.close('left-menu')"
      >
        <ion-content left>
          <ion-header :translucent="true" mode="md" class="dynamicIslandHeaderFix">
            <!-- added toolbar, so that the header has the same height on the left/right menu as on the main menu -->
            <ion-toolbar class="header">
              <ion-list>
                <ion-item class="menuTitle">
                  <ion-label> Menu </ion-label>
                </ion-item>
              </ion-list>
            </ion-toolbar>
          </ion-header>
          <VarusChurchSeasonDynamic />
          <ion-list id="left-menu-list" mode="ios">
            <VarusMenuLeftSide />
          </ion-list>
          <!-- show store infos when in dev mode -->
          <div v-if="isDevelopment">
            <!--VarusPinia /-->
          </div>
        </ion-content>
      </ion-menu>
      <!-- LEFT MENU - Ende -->
      <!-- RIGHT MENU - Start -->
      <ion-menu
        menuId="right-menu"
        side="end"
        contentId="main-content"
        type="overlay"
        :disabled="!useRoute().meta.showRightMenu"
        @click="menuController.close('right-menu')"
        @ionDidOpen="onRightMenuOpened"
      >
        <ion-content right :scroll-events="true">
          <ion-header class="dynamicIslandHeaderFix">
            <!-- added toolbar, so that the header has the same height on the left/right menu as on the main menu -->
            <ion-toolbar class="header">
              <ion-list>
                <ion-item class="menuTitle">
                  <ion-label>
                    <!-- Table of Content -->
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-toolbar>
          </ion-header>
          <VarusTableOfPrayers />
        </ion-content>
      </ion-menu>
      <!-- RIGHT MENU - Ende -->
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
  </IonApp>
</template>

<style>
.chips {
  color: white;
}

ion-item {
  --min-height: 10px;
}

ion-list-header {
  font-weight: bold;
  min-height: 10px;
  padding-top: 28px;
}

ion-item.selected {
  --color: var(--ion-color-secondary);
}
.dynamicIslandHeaderFix {
  padding-top: var(--ion-safe-area-top);
}
</style>
