import { defineStore } from "pinia";
import { DebugLevel, appLog } from "../functions/Logging";

const storeName = "ScrollStore";

appLog(DebugLevel.INFO, "[Pinia]", storeName + " initializing ...");

export const useScrollStore = defineStore("scroll", {
  state: () => ({
    scrollElementMain: "",
    ionContentMain: {},
    ionContentRight: {},
  }),
});

appLog(DebugLevel.INFO, "[Pinia]", storeName + " initialized.");
