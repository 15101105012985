import { getDay, getMonth, getYear, getWeekday, getMonthName } from "../services/DateFormatService";
import { CALENDARS } from "@/enums/Calendars.js";

const STYLE = "short";

export function calendarDate(language, calendar, date) {
  const year = getYear(language, calendar, date);
  const month = getMonth(language, calendar, date);
  const day = getDay(language, calendar, date);
  const mnShort = getMonthName(language, calendar, date, STYLE);
  const mn = getMonthName(language, calendar, date);
  const wdShort = getWeekday(language, calendar, date, STYLE);
  const wd = getWeekday(language, calendar, date);

  // alternative Calendar (the other one than the one provided)
  const altCal = calendar === CALENDARS.COPT ? CALENDARS.GREG : calendar === CALENDARS.GREG ? CALENDARS.COPT : null;
  const altData = altCal
    ? {
        year: getYear(language, altCal, date),
        day: getDay(language, altCal, date),
        mnShort: getMonthName(language, altCal, date, STYLE),
      }
    : null;

  const formatted = `${wdShort}, ${day}. ${mnShort} ${year}`;
  const formatted2 = `${day}. ${mnShort}`;
  const combined = altData ? `${formatted} (${altData.day}. ${altData.mnShort} ${altData.year})` : formatted;

  return {
    date,
    y: year,
    m: month,
    d: day,
    mn,
    mnShort,
    wd,
    wdShort,
    formatted,
    formatted2,
    combined,
  };
}
