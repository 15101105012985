<script setup>
import { IonChip, IonLabel } from "@ionic/vue";
import { storeToRefs } from "pinia";
import { useCalendarStore } from "../stores/calendarStore";
import { computed } from "vue";

// calStore
const calStore = useCalendarStore();
const { getIsEveOf } = storeToRefs(calStore);
const props = defineProps({ cal: String, fullDateDisplay: Boolean, linkToCal: Boolean });

const date2display = computed(() => {
  if (props.fullDateDisplay === undefined || props.fullDateDisplay) {
    return calStore.varusDate?.get(props.cal)?.formatted2;
  } else {
    return calStore.varusDate?.get(props.cal)?.formatted;
  }
});

const calendarLink = computed(() => {
  return props.linkToCal == true ? "/churchSeason" : "";
});
</script>

<template>
  <router-link :to="calendarLink" replace>
    <ion-chip>
      <ion-label>
        {{ date2display }}
        <div>
          <span v-if="getIsEveOf && cal == 'coptic'"> ({{ $t("ui.calendar.eve") }}) </span>
        </div>
      </ion-label>
    </ion-chip>
  </router-link>
</template>
<style scoped>
ion-label {
  font-weight: bolder;
  text-align: center;
}
</style>
