<script setup>
import { IonItem, IonLabel, IonIcon } from "@ionic/vue";
import { computed } from "vue";
import { onMobileDevice } from "../utils/EnvironmentUtils";

const props = defineProps({
  type: String,
  title: String,
  linkPath: String,
  icon: String,
  display: Boolean,
  onlyOnMobile: Boolean,
  cssClass: String,
});

const isDevelopment = process.env.NODE_ENV === "development";

let renderThisItem = computed(() => {
  if (props.display == false) return false;
  if (devCheck() == true && mobileCheck() == true) {
    return true;
  } else {
    return false;
  }
});

const devCheck = () => {
  // dev check
  if (props.onlyInDevMode && isDevelopment) {
    return true;
  } else if (props.onlyInDevMode && !isDevelopment) {
    return false;
  }
  return true;
};

const mobileCheck = () => {
  // mobile check
  if (props.onlyOnMobile && onMobileDevice()) {
    return true;
  } else if (props.onlyOnMobile && !onMobileDevice()) {
    return false;
  }
  return true;
};
</script>

<template>
  <ion-item v-if="renderThisItem && type == 'menuHeader'" class="menuTitle">
    <ion-label>
      <h2>{{ $t(title) }}</h2>
    </ion-label>
  </ion-item>

  <ion-item v-else-if="renderThisItem" button :router-link="linkPath" :class="cssClass">
    <ion-label>
      <h2>{{ $t(title) }}</h2>
    </ion-label>
    <ion-icon :icon="icon" slot="start"></ion-icon>
  </ion-item>
</template>
