import { waitForElement } from "@/utils/GeneralUtils";
import { DebugLevel, appLog } from "@/functions/Logging";
import { useScrollStore } from "../stores/scrollStore";
import { storeToRefs } from "pinia";

const compName = "[ScrollUtils]";

export const scrollToElement = async (targetID) => {
  const scrollStore = useScrollStore();
  const { ionContentMain } = storeToRefs(scrollStore);

  if (!ionContentMain?.value) {
    appLog(DebugLevel.ERROR, compName, "IonContent reference is missing.");
    return Promise.resolve({ left: 0, top: 0 });
  }

  waitForElement(targetID)
    .then((element) => {
      appLog(DebugLevel.SCROLL, compName, `targetID: ${targetID}`);
      if (!ionContentMain) {
        appLog(DebugLevel.ERROR, compName, `ScrollUtils - Content ${ionContentMain} NOT found!`);
        return;
      }

      // Calculate position relative to the scrollable container
      let posY = 400;
      const rect = element.getBoundingClientRect();
      const containerRect = ionContentMain?.value.$el.getBoundingClientRect();
      appLog(DebugLevel.SCROLL, compName, `getBoundingClientRect:}`, rect);
      if (typeof rect !== "undefined") {
        posY = rect.top - containerRect.top + ionContentMain?.value.$el.scrollTop - 120;
      }

      // Scroll with animation
      appLog(DebugLevel.SCROLL, compName, `Scrolling with animation to y-postion ${posY}`);
      ionContentMain.value.$el.scrollByPoint(0, posY, 500); // 500ms duration
    })
    .catch((error) => {
      appLog(DebugLevel.ERROR, compName, error);
    });
};

/**
 * This is a helper function, which attempts to locate the element multiple times (default: 10 retries).
 * A delay (100ms by default) is added between retries to give the DOM time to fully load.
 *
 * @param {*} selector
 * @param {*} retries
 * @param {*} delay
 * @returns
 */
export function scrollToElementWithRetry(selector, retries = 10, delay = 100) {
  const scrollStore = useScrollStore();
  const { ionContentMain } = storeToRefs(scrollStore);

  if (!ionContentMain?.value) {
    appLog(DebugLevel.ERROR, compName, "IonContent reference is missing.");
    return Promise.resolve({ left: 0, top: 0 });
  }

  const scrollToTarget = async (attemptsLeft) => {
    const targetElement = document.getElementById(selector);
    appLog(DebugLevel.SCROLL, compName, `Attempting scroll to: ${selector}`, { attemptsLeft });

    if (!targetElement) {
      if (attemptsLeft > 0) {
        appLog(DebugLevel.SCROLL, compName, `Retrying... ${attemptsLeft} attempts left.`);
        await new Promise((resolve) => setTimeout(resolve, delay));
        return scrollToTarget(attemptsLeft - 1);
      }
      appLog(DebugLevel.ERROR, compName, `Element not found: ${selector}`);
      return { left: 0, top: 0 };
    }

    const yOffset = targetElement.getBoundingClientRect().top - 60;
    appLog(DebugLevel.SCROLL, compName, "Scrolling to position:", { yOffset });

    try {
      await ionContentMain.value.$el.scrollByPoint(0, yOffset, 500);
      appLog(DebugLevel.SCROLL, compName, "Scroll successful.");
      return { left: 0, top: yOffset };
    } catch (error) {
      appLog(DebugLevel.ERROR, compName, "Scroll failed:", error);
      return { left: 0, top: 0 };
    }
  };

  return scrollToTarget(retries);
}
