import { defineStore } from "pinia";
import { watch } from "vue";
import { configs } from "../utils/ConfigUtil";
import { getItem, setItem, localStorageKeyNames } from "../services/LocalStorageService";
import { DebugLevel, appLog } from "../functions/Logging";
import Bugsnag from "@bugsnag/js";

const storeName = "UserSettingsStore";

appLog(DebugLevel.INFO, "[Pinia]", storeName + " initializing ...");

/**
 * checks if setting value is store in Storage. If yes use this, if not, use default defined value;
 * @param {*} settingObject
 */
async function checkStorage(settingObject) {
  let name = settingObject.name;
  let defaultVal = settingObject.defaultVal;
  let localSettingName = settingObject.localStorageName;
  // checking for values in Storage
  async function readingStorage() {
    return await getItem(localSettingName, true);
  }

  await readingStorage()
    .then((res) => {
      if (res == null) {
        // No Value localStorage - setting to default
        appLog(
          DebugLevel.INFO,
          "[Pinia]",
          name + ` | No value in Storage for ${localSettingName} Use default from pinia store.`,
          defaultVal
        );
        setItem(localSettingName, defaultVal, true);
      } else {
        // Reading from localStorage
        // let localStorageValue = res;
        settingObject.localStorageVal = res;
        appLog(DebugLevel.INFO, "[Pinia]", name + " | Read from Storage");
      }
    })
    .catch(Bugsnag.notify);
}

// ========
// Settings
// ========
const uiLanguageSetting = {
  name: "UI Language",
  defaultVal: configs?.getDefaultUiLanguage()[0],
  localStorageName: localStorageKeyNames.LANGUAGE_UI,
  localStorageVal: "",
};
const userTextLangSetting = {
  name: "Text Languages",
  defaultVal: configs?.getDefaultTextLanguages(),
  localStorageName: localStorageKeyNames.LANGUAGES_TEXT,
  localStorageVal: "",
};
const bibleTranslSetting = {
  name: "Bible Translation",
  defaultVal: configs?.default_settings?.content_settings?.bible_translations,
  localStorageName: localStorageKeyNames.TRANSLATIONS,
  localStorageVal: "",
};
const darkModeSetting = {
  name: "Dark Mode",
  defaultVal: configs.default_settings?.display_settings.dark_mode,
  localStorageName: localStorageKeyNames.DARK_MODE,
  localStorageVal: "",
};
const baseFontSizeSetting = {
  name: "Basic Font Size",
  defaultVal: configs.default_settings?.display_settings.base_font_size,
  localStorageName: localStorageKeyNames.BASE_FONT_SIZE,
  localStorageVal: "",
};

// Iterate over Settings
const makeSettings = async () => {
  let initialSettings = [
    uiLanguageSetting,
    userTextLangSetting,
    bibleTranslSetting,
    darkModeSetting,
    baseFontSizeSetting,
  ];

  await Promise.allSettled(
    initialSettings.map((currentSetting) => {
      checkStorage(currentSetting);
    })
  );

  return initialSettings;
};

export const settings = await makeSettings();

// =========
// S T O R E
// =========
export const useUserSettingsStoreFactory = defineStore({
  id: "userSettingsStore",
  state: () => ({
    uiLanguage:
      uiLanguageSetting.localStorageVal !== "" ? uiLanguageSetting.localStorageVal : uiLanguageSetting.defaultVal,
    userTextLanguages:
      userTextLangSetting.localStorageVal !== "" ? userTextLangSetting.localStorageVal : userTextLangSetting.defaultVal,
    userTranslationSetting:
      bibleTranslSetting.localStorageVal !== "" ? bibleTranslSetting.localStorageVal : bibleTranslSetting.defaultVal,
    darkMode:
      darkModeSetting.localStorageVal !== undefined ? darkModeSetting.localStorageVal : darkModeSetting.defaultVal,
    baseFontSize:
      baseFontSizeSetting.localStorageVal !== "" ? baseFontSizeSetting.localStorageVal : baseFontSizeSetting.defaultVal,
  }),
  getters: {
    textLangContains: (state) => {
      return (lang) => state.userTextLanguages.some((l) => l.key === lang);
    },
    getAgpeyaPsalmTranslationForDEUonly() {
      return this.userTranslationSetting["agpeya"]["AT"].translation;
    },
    /**
     * currently fixed for de, ara and cop, TODO later: add translation settings for every language
     *
     * @param {*} state
     * @returns
     */
    getBibleTransl: (state) => {
      return (lang, book) =>
        lang == "deu" ? state.userTranslationSetting["agpeya"][book].translation : lang == "ara" ? "ARA" : "COT";
    },
  },
  actions: {
    addLanguage(langToAdd) {
      this.userTextLanguages.push(langToAdd);
    },
    removeLanguage(langToRemove) {
      this.userTextLanguages = this.userTextLanguages.filter((item) => item.key !== langToRemove);
    },
  },
});

appLog(DebugLevel.INFO, "[Pinia]", "userSettingsStore Watch!");

let store = null;
export const useUserSettingsStore = Object.assign((..._piniaArgs) => {
  if (!store) {
    store = useUserSettingsStoreFactory(..._piniaArgs);

    watch(
      () => store.uiLanguage,
      (uiLang) => {
        appLog(DebugLevel.DEBUG, "[Pinia]", "useUserSettingsStore Watch | Changing Storage uiLang to new value");
        appLog(DebugLevel.DEBUG, "[Pinia]", "useUserSettingsStore Watch | New value ", uiLang);
        setItem(uiLanguageSetting.localStorageName, uiLang, true);
      },
      { deep: true }
    );
    watch(
      () => store.userTextLanguages,
      (userTextLanguage) => {
        appLog(
          DebugLevel.DEBUG,
          "[Pinia]",
          "useUserSettingsStore Watch | Changing Storage userTextLanguage to new value"
        );
        appLog(DebugLevel.DEBUG, "[Pinia]", "useUserSettingsStore Watch | New value ", userTextLanguage);
        setItem(userTextLangSetting.localStorageName, userTextLanguage, true);
      },
      { deep: true }
    );
    watch(
      () => store.userTranslationSetting,
      (bibleTransl) => {
        appLog(DebugLevel.DEBUG, "[Pinia]", "useUserSettingsStore Watch | Changing Storage bibleTransl to new value");
        appLog(DebugLevel.DEBUG, "[Pinia]", "useUserSettingsStore Watch | New value ", bibleTransl);
        setItem(bibleTranslSetting.localStorageName, bibleTransl, true);
      },
      { deep: true }
    );
    watch(
      () => store.darkMode,
      (dm) => {
        appLog(DebugLevel.DEBUG, "[Pinia]", "darkModeSetting Watch | Changing Storage darkModeSetting to new value");
        appLog(DebugLevel.DEBUG, "[Pinia]", "darkModeSetting Watch | New value ", dm);
        setItem(darkModeSetting.localStorageName, dm, true);
      },
      { deep: true }
    );
    watch(
      () => store.baseFontSize,
      (fontSize) => {
        appLog(DebugLevel.DEBUG, "[Pinia]", "baseFontSize Watch | Changing Storage baseFontSize to new value");
        appLog(DebugLevel.DEBUG, "[Pinia]", "baseFontSize Watch | New value ", fontSize);
        setItem(baseFontSizeSetting.localStorageName, fontSize, true);
      },
      { deep: true }
    );
  }

  return store;
}, useUserSettingsStoreFactory);

appLog(DebugLevel.INFO, "[Pinia]", storeName + " initialized.");
