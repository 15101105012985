<script setup>
import { IonText } from "@ionic/vue";
import { storeToRefs } from "pinia";
import { useCalendarStore } from "../stores/calendarStore";

// Pinia - calStore
const calStore = useCalendarStore();
const { getChurchSeason } = storeToRefs(calStore);
</script>

<template v-if="getChurchSeason">
  <ion-text id="main">{{ getChurchSeason.titleDisplay?.[$i18n.locale] }}</ion-text>
  <ion-text id="sub" class="copticText2">{{ getChurchSeason.titleDisplay?.["cop"] }}</ion-text>
</template>

<style scoped>
#main {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
#sub {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
</style>
