<script setup>
import { IonList, IonItem, IonLabel, IonButton } from "@ionic/vue";
import { storeToRefs } from "pinia";
import { useUserSettingsStore } from "../stores/userSettingsStore";
import { ref, computed } from "vue";
import BaseSettingList from "./base/BaseSettingList.vue";
import useEventsBus from "../services/EventBusService";
import { EMIT_FONTSIZE } from "../services/EventBusConstants";

// pinia Store - get userSettings from Store
const userSettingsStore = useUserSettingsStore();
const { baseFontSize } = storeToRefs(userSettingsStore);

const textSizeRem = ref(baseFontSize);
const textSizeDelta = 1;

const displayValue = computed(() => {
  return Math.floor(textSizeRem.value * 10);
});

function updateVal(textSizeRemNew) {
  // von displayValue zu Rem umrechnen
  textSizeRem.value = textSizeRemNew / 10;
  // emit
  const { emit } = useEventsBus();
  emit(EMIT_FONTSIZE, textSizeRem.value);
}

function decreaseTextSize() {
  updateVal(displayValue.value - textSizeDelta);
}
function increaseTextSize() {
  updateVal(displayValue.value + textSizeDelta);
}
</script>

<template>
  <!-- Font Size -->
  <base-setting-list :setting-title="$t('ui.settings.fontsize')">
    <ion-list>
      <ion-item class="ion-text-center">
        <ion-button slot="start" @click="decreaseTextSize">-</ion-button>
        <ion-label>{{ displayValue }}</ion-label>
        <ion-button slot="end" @click="increaseTextSize">+</ion-button>
      </ion-item>
    </ion-list>
  </base-setting-list>
</template>
