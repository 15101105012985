import { DebugLevel, appLog } from "../functions/Logging";
import { inRange } from "../utils/GeneralUtils";
import { FeastFastTimeType } from "../enums/FeastAndFastType";
import { getWeekDayOfCopticFeast } from "./FeastService";

import FASTS from "../data/fasts.json";

const fileName = "[FastService]";

const getAllNonMovable = () => {
  return FASTS.filter((fast) => fast.time_type == FeastFastTimeType.NON_MOVABLE.name);
};

const getAllMovable = () => {
  return FASTS.filter((fast) => fast.time_type == FeastFastTimeType.MOVABLE.name);
};

const getAllWeekly = () => {
  return FASTS.filter((fast) => fast.time_type == FeastFastTimeType.WEEKLY.name);
};

const matchMovable = (daysToEastern) => {
  return FASTS_MOVABLE.filter((season) =>
    inRange(season.times.daysToEasternStart, season.times.daysToEasternEnd, daysToEastern)
  );
};

const getFastByKey = (key) => {
  return FASTS.filter((fast) => fast.key == key)[0];
};

const matchMovableStart = (daysToEastern, month, day) => {
  let fast2return = FASTS_MOVABLE.filter(
    (fast) =>
      fast.times.daysToEasternStart != undefined &&
      Math.abs(daysToEastern) >= Math.abs(fast.times.daysToEasternStart) &&
      month == fast.times.monthEnd &&
      day <= fast.times.dayEnd
  );
  return fast2return;
};

const matchNonMovable = (month, day) => {
  return FASTS_NONMOVABLE.filter(
    (fast) =>
      day >= fast.times.dayStart &&
      day <= fast.times.dayEnd &&
      month >= fast.times.monthStart &&
      month <= fast.times.monthEnd
  );
};

const matchWeekly = (day) => {
  return FASTS_WEEKLY.filter((fast) => fast.monthlyDay == day);
};

const getParamounDay = (copticYear) => {
  // get on which workday nativity
  const wday = getWeekDayOfCopticFeast(copticYear, "christ_nativity");
  let paramonDay;
  if (wday == 1) {
    // Nativity on Monday
    paramonDay = 26;
  } else if (wday > 1) {
    // Nativity day before Nativity
    paramonDay = 28;
  } else {
    paramonDay = -1;
  }
  return paramonDay;
};

appLog(DebugLevel.DEBUG, fileName, fileName + " ... Start");
const FASTS_NONMOVABLE = getAllNonMovable();
const FASTS_MOVABLE = getAllMovable();
const FASTS_WEEKLY = getAllWeekly();
appLog(DebugLevel.DEBUG, fileName, fileName + " ... END");

/**
 *
 * @param {*} year coptic format
 * @param {*} month coptic format
 * @param {*} day coptic format
 * @returns
 */
const getFasts = function (cDate, daysToEastern) {
  const cYear = cDate.y;
  const cMonth = cDate.m;
  const cDay = cDate.d;
  appLog(DebugLevel.DEBUG, fileName, `getFasts for ${cYear}-${cMonth}-${cDay}`);

  // filter for non-movable
  const nonMovable = matchNonMovable(cMonth, cDay);

  // filter for movable
  const movable = matchMovable(daysToEastern);
  const movable2 = matchMovableStart(daysToEastern, cMonth, cDay);

  // filter for weekly
  const weekly = matchWeekly(cDay);

  let fasts = [];

  // Paramoin - special
  const paraDay = getParamounDay(cYear);
  if (paraDay > 0 && paraDay == cDay && cMonth == 4) {
    fasts.push(getFastByKey("nativity_paramoun"));
    return fasts;
  }

  // join
  fasts = nonMovable.concat(movable, movable2, weekly);
  return fasts;
};

export { getFasts, getFastByKey };
