import languages from "../data/config/languages.json";
import appInfo from "../data/config/app.json";
import default_settings from "../data/config/default_settings.json";
import bibleTranslations from "../data/config/bible_translations.json";
import tunes from "../data/tunes.json";
import roles from "../data/roles.json";
import refrains from "../data/refrains.json";
import liturgy_services from "../data/liturgy_services.json";
import monthsCoptic from "../data/months_coptic.json";
import months from "../data/months.json";
import readings from "../data/lesungen/readings.json";
import readingTypes from "../data/lesungen/reading_types.json";
import matins from "../data/euchologion/matins.json";
import vesper from "../data/euchologion/vesper.json";
import liturgy from "../data/euchologion/liturgy.json";

function getUILanguages() {
  return languages?.filter((a) => a.uiLanguage) || [];
}

function getTextLanguages() {
  return languages?.filter((a) => a.textLanguage) || [];
}

function getDefaultUiLanguage() {
  return languages?.filter((a) => default_settings.app_settings.language) || [];
}

function getDefaultTextLanguages() {
  return languages?.filter((a) => default_settings.content_settings.text_languages) || [];
}

function getLanguageByKey(key) {
  return languages?.find((obj) => obj.key === key) || null;
}

function getTuneByKey(key) {
  return tunes?.find((obj) => obj.key === key) || {};
}

function getRefrainsByKey(key) {
  return refrains?.find((obj) => obj.key === key) || null;
}

function getReadingTypeByKey(key) {
  return readingTypes?.find((obj) => obj.key === key) || null;
}

function getCopticMonthName(id) {
  return monthsCoptic?.find((obj) => obj.id == id) || null;
}

export const configs = {
  languages,
  appInfo,
  default_settings,
  bibleTranslations,
  tunes,
  roles,
  refrains,
  readings,
  readingTypes,
  matins,
  vesper,
  liturgy,
  liturgy_services,
  getCopticMonthName,
  months,
  getUILanguages,
  getDefaultUiLanguage,
  getDefaultTextLanguages,
  getTextLanguages,
  getLanguageByKey,
  getTuneByKey,
  getRefrainsByKey,
  getReadingTypeByKey,
};
