<script setup>
import { IonTitle, IonItem, IonList } from "@ionic/vue";
import { ref } from "vue";
import { useRoute } from "vue-router";

import VarusPiniaStoreViewer from "@/components/VarusPiniaStoreViewer.vue";

import { useCalendarStore } from "../stores/calendarStore";
import { useReadingsStore } from "../stores/readingsStore";
import { useUserSettingsStore } from "../stores/userSettingsStore";
import { useBookStore } from "../stores/bookStore";
import { useScrollStore } from "../stores/scrollStore";

const calendarStore = useCalendarStore();
const readingsStore = useReadingsStore();
const userSettingsStore = useUserSettingsStore();
const bookStore = useBookStore();
const scrollStore = useScrollStore();

// Route
const route = useRoute();

const stores = ref([
  {
    id: "cal-store",
    name: "Calendar Store",
    storeInstance: calendarStore,
  },
  {
    id: "readings-store",
    name: "Readings Store",
    storeInstance: readingsStore,
  },
  {
    id: "user-store",
    name: "User Setting Store",
    storeInstance: userSettingsStore,
  },
  {
    id: "book-store",
    name: "Book Store",
    storeInstance: bookStore,
  },
  {
    id: "scroll-store",
    name: "Scroll Store",
    storeInstance: scrollStore,
  },
]);
</script>

<template>
  <ion-title>Pinia Stores</ion-title>

  <!-- Navigation Links -->
  <ion-list lines="none">
    <!-- -->
    <ion-item v-for="store in stores" :key="store.id" button :href="`#${store.id}`">
      <ion-item>
        <router-link :to="{ path: route.path, hash: '#' + store.id }">{{ store.name }}</router-link>
      </ion-item>
    </ion-item>
  </ion-list>

  <!-- StoreStateViewer Components -->
  <VarusPiniaStoreViewer v-for="store in stores" :key="store.id" :store="store.storeInstance" :storeId="store.id" />
</template>
