<!-- VarusPiniaStoreItem.vue -->
<script setup>
import { defineProps, computed } from "vue";
import { IonItem, IonLabel, IonText } from "@ionic/vue";

// Define the props
const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  value: {
    type: [Object, Array, String, Number, Boolean],
    required: false, // Allow undefined
    default: null,
    validator: (value) => {
      // Allow null, undefined, and the specified types
      if (value === null || value === undefined) return true;
      const valueType = typeof value;
      return valueType === "object" || valueType === "string" || valueType === "number" || valueType === "boolean";
    },
  },
});

const isNull = computed(() => props.value === null);

// Computed property to format the value as JSON
const formattedValue = computed(() => {
  try {
    return JSON.stringify(props.value, null, 2);
  } catch (e) {
    return String(props.value);
  }
});
</script>

<template>
  <ion-item lines="none">
    <ion-label>
      <ion-text class="statename">
        {{ label }}
      </ion-text>
      <ion-text color="medium">
        <pre v-if="formattedValue !== null">{{ formattedValue }}</pre>
        <pre v-else-if="isNull" class="null-value">null</pre>
        <pre v-else class="undefined-value">undefined</pre>
      </ion-text>
    </ion-label>
  </ion-item>
</template>

<style scoped>
ion-label {
  margin: 0;
}
ion-item {
  --padding-start: 0;
  --inner-padding: 0;
  padding: 0px;
  border: 0;
}
.statename {
  color: black;
  font-size: 0.8rem;
}
pre {
  font-size: 0.8rem;
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: black;
  padding: 0;
  margin: 0;
}
</style>
