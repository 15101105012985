// src/stores/readingsStore.js

import { defineStore } from "pinia";
import { getKatamerosSeason, getReading } from "../seasonservice/KatamerosSeasonService.js";

export const useReadingsStore = defineStore("readingsStore", {
  state: () => ({
    katameros: null,
    reading: null,
  }),

  getters: {
    getReadingText: (state) => (service, rType) => state.reading?.liturgyServices[service][rType],

    getReadingReference: (state) => (service, rType) => state.reading?.liturgyServices[service][rType]?.reference,

    getGospelAuthor: (state) => (service) => state.reading?.liturgyServices[service]?.gospel.author,

    getKatamerosSeason: (state) => state.katameros,
  },

  actions: {
    setKatameros(churchSeason, dayOfWeek) {
      this.katameros = getKatamerosSeason(churchSeason, dayOfWeek);
    },

    setReading(katameros, churchSeason, daysToEastern, coptDate) {
      this.reading = getReading(katameros, churchSeason, daysToEastern, coptDate);
    },

    initializeReadings(churchSeason, dayOfWeek, daysToEastern, coptDate) {
      this.setKatameros(churchSeason, dayOfWeek);
      this.setReading(this.katameros, churchSeason, daysToEastern, coptDate);
    },
  },
});
