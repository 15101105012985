<script setup>
// Date List
import { IonGrid, IonRow, IonCol, IonImg } from "@ionic/vue";
// don't display, because a) text is only available in German and b) German Text needs correction (Artikelverwendung)
// eslint-disable-next-line no-unused-vars
import VarusTodayIs from "../components/VarusTodayIs.vue";
// eslint-disable-next-line no-unused-vars
import VarusChipTune from "../components/VarusChipTune.vue";
// eslint-disable-next-line no-unused-vars
import VarusChipFasting from "../components/VarusChipFasting.vue";
import VarusChurchSeasonText from "../components/VarusChurchSeasonText.vue";
import VarusChipDate from "../components/VarusChipDate.vue";
import { useCalendarStore } from "../stores/calendarStore";

// calStore
const calStore = useCalendarStore();
</script>

<template>
  <ion-grid>
    <ion-row>
      <ion-col class="ion-align-self-center">
        <varus-church-season-text />
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-align-self-center">
        <ion-img class="seasonPic" :src="calStore.seasonPicPath" />
      </ion-col>
      <ion-col class="ion-align-self-center">
        <varus-chip-date cal="gregory" :linkToCal="true" />
        <br />
        <varus-chip-date cal="coptic" :linkToCal="true" />
        <!-- <p>
          <varus-today-is />
        </p>-->
        <!--
        <p />
        <varus-chip-tune />
        <p />
        <varus-chip-fasting />
        -->
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<style scoped>
ion-col {
  text-align: center;
  border: 0;
}
</style>
