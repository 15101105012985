<script>
export default {
  name: "BaseLayout",
};
</script>
<script setup>
import {
  IonMenuButton,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  modalController,
} from "@ionic/vue";
import { chevronBackOutline, eyeSharp } from "ionicons/icons";
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useScrollStore } from "@/stores/scrollStore.js";

import DisplaySettingModal from "./DisplaySettingModal";

// eslint-disable-next-line no-unused-vars
import VarusChurchSeasonSwitch from "../VarusChurchSeasonSwitch.vue";

// Pinia - ScrollStore
const scrollStore = useScrollStore();
const { ionContentMain } = storeToRefs(scrollStore);

// store IonContent-Ref in ScrollStore
const contentMain = ref(null);
onMounted(() => {
  if (contentMain.value) {
    ionContentMain.value = contentMain;
  }
});

// PROPS
defineProps({
  pageTitle: String,
  displayBackLink: Boolean,
  pageDefaultBackLink: String,
  useHistory: Boolean,
});

// Modal
const openModal = async () => {
  const modal = await modalController.create({ component: DisplaySettingModal });
  modal.present();
  await modal.onWillDismiss();
};
</script>

<template>
  <ion-page ref="page">
    <ion-header :translucent="true" mode="md">
      <ion-toolbar class="header">
        <ion-buttons slot="start">
          <ion-menu-button menu="left-menu"></ion-menu-button>
          <!-- ion-back-button:
          - navigates back in the app's history when clicked.
          - displayed only when there is history in the navigation stack, unless defaultHref is set.  -->
          <ion-back-button v-if="useHistory && displayBackLink" :default-href="pageDefaultBackLink"> </ion-back-button>
          <ion-button v-if="!useHistory && displayBackLink" :router-link="pageDefaultBackLink">
            <ion-icon slot="start" :icon="chevronBackOutline" />
          </ion-button>
        </ion-buttons>
        <ion-title id="headerTitle">{{ pageTitle }}</ion-title>

        <!--varus-church-season-switch/-->

        <!-- Eye Icon for View Settings -->
        <ion-icon
          v-if="useRoute().meta.showDisplayOptionsIcon"
          slot="end"
          :icon="eyeSharp"
          expand="block"
          style="margin-right: 60px"
          @click="openModal"
        />

        <ion-buttons slot="end">
          <ion-menu-button menu="right-menu"></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content main ref="contentMain">
      <slot />
    </ion-content>
  </ion-page>
</template>
<style scoped>
.header {
  color: white;
  background-color: transparent;
}
ion-modal {
  --width: 400px;
  --height: 50%;
}
</style>
