<script>
export default {
  name: "VarusSideMenuLink",
};
</script>
<script setup>
import { IonItem, IonLabel, IonIcon } from "@ionic/vue";
import { chevronForwardOutline, chevronBackOutline } from "ionicons/icons";
import { configs } from "../utils/ConfigUtil";
import { computed } from "vue";
import { useBookStore } from "../stores/bookStore";

// BookStore
const bookStore = useBookStore();

const props = defineProps({ element: Object, sideMenuPosition: String, isSelectedElement: Boolean });

const elementToDisplay = computed(() => {
  let child = JSON.parse(JSON.stringify(props.element));
  if (typeof child.titleDisplay == "undefined") {
    return bookStore.metaData.get(child.key);
  } else {
    return child;
  }
});
</script>

<template>
  <ion-item
    :disabled="element.disabled"
    :class="elementToDisplay?.type"
    :color="isSelectedElement ? 'medium' : undefined"
  >
    <ion-label v-if="elementToDisplay?.titleDisplay != undefined">
      <span v-html="elementToDisplay?.titleDisplay?.[$i18n.locale]"></span>
      <br />
      <span :class="configs.getLanguageByKey('cop').cssTitle">{{ elementToDisplay?.titleDisplay?.["cop"] }}</span>
    </ion-label>
    <ion-icon
      :slot="sideMenuPosition == 'left' ? 'end' : 'start'"
      :icon="sideMenuPosition == 'left' ? chevronForwardOutline : chevronBackOutline"
      class="component-icon component-icon-primary"
    ></ion-icon>
  </ion-item>
</template>

<style scoped>
.silentPrayer {
  text-align: left;
  opacity: 40%;
}
.booklist,
.prayerlist {
  color: rgb(68, 0, 255);
}
.psalm,
.prayer,
.insertPrayer {
  text-align: left;
}
</style>
