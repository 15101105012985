<script setup>
import { IonCol, IonRow } from "@ionic/vue";
import { useUserSettingsStore } from "../stores/userSettingsStore";
import { storeToRefs } from "pinia";
import { langContainsText } from "../utils/GeneralUtils";
import VarusText from "../components/VarusText.vue";
import VarusParagraphRole from "../components/VarusParagraphRole.vue";
import VarusParagraphList from "../components/VarusParagraphList.vue";
import { getParagraph } from "../services/PrayerImportService";
import { computed } from "vue";
import { configs } from "../utils/ConfigUtil";
const userStore = useUserSettingsStore();
const { userTextLanguages } = storeToRefs(userStore);

const props = defineProps({ paragraph: Object, cssstyleclass: String, type: String });

let refrain = undefined;
if (typeof props.paragraph !== "undefined" && typeof props.paragraph.refrain !== "undefined") {
  refrain = configs.getRefrainsByKey(props.paragraph.refrain);
}

function setOpacity(paragraph) {
  if (paragraph.silent == true) {
    return "silentText";
  }
}

const importedParagraphs = computed(() => getParagraph(props.paragraph));
</script>

<template>
  <div v-if="paragraph">
    <VarusParagraphRole v-if="paragraph.role" :paragraph="paragraph" :role="paragraph.role" />

    <template v-if="paragraph.type == 'insertParagraph'">
      <!-- some kind of a recursive call -->
      <VarusParagraphList :paragraphs="importedParagraphs" />
    </template>

    <!-- Amount of columns is taken from the german array in the JSON files -->
    <template v-else>
      <template v-for="(p, index) in paragraph.text['deu']" :key="index">
        <ion-row :class="cssstyleclass">
          <template v-for="lang in userTextLanguages" :key="lang.key">
            <ion-col v-if="langContainsText(paragraph.text, lang.key)" :class="setOpacity(paragraph)">
              <VarusText :texttodisplay="paragraph.text[lang.key][index]" :lang="lang" :class="cssstyleclass" />
            </ion-col>
          </template>
        </ion-row>
      </template>
      <!-- Refrain -->
      <div v-if="refrain && refrain.text">
        <template v-for="(p, index) in refrain.text['deu']" :key="index">
          <ion-row>
            <template v-for="lang in userTextLanguages" :key="lang.key">
              <ion-col v-if="langContainsText(refrain.text, lang.key)">
                <VarusText :texttodisplay="refrain.text[lang.key][index]" :lang="lang" class="refrainParagraph" />
              </ion-col>
            </template>
          </ion-row>
        </template>
      </div>
    </template>
  </div>
</template>

<style scoped>
.paragraph {
  border: 0px solid red;
  /* border: 2px solid #1be700; */
  /* --ion-grid-column-padding	: 1px; */
}
.refrainParagraph {
  font-style: italic;
  margin-bottom: 20px;
}
ion-row {
  border: 0px solid rgb(0, 132, 255);
}
ion-col {
  border: 0px solid rgb(255, 0, 191);
  margin-right: 3px;
}

.silentText {
  opacity: 30%;
}
</style>
