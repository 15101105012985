/**
 * Checks if the current date is within the specified range.
 *
 * @param {Object} current - The current date.
 * @param {number} current.month - The current month (1-13).
 * @param {number} current.day - The current day (1-30 or 1-5/6 for month 13).
 * @param {Object} start - The start date of the range.
 * @param {number} start.month - The start month (1-13).
 * @param {number} start.day - The start day (1-30 or 1-5/6 for month 13).
 * @param {Object} end - The end date of the range.
 * @param {number} end.month - The end month (1-13).
 * @param {number} end.day - The end day (1-30 or 1-5/6 for month 13).
 * @param {boolean} isLeapYear - Indicates if it's a leap year (for month 13).
 * @returns {boolean} True if current is within range, else false.
 */
export const isDateWithinRange = (current, start, end, isLeapYear = false) => {
  try {
    const currentOrdinal = toOrdinal(current, isLeapYear);
    const startOrdinal = toOrdinal(start, isLeapYear);
    const endOrdinal = toOrdinal(end, isLeapYear);

    if (startOrdinal <= endOrdinal) {
      // Range does not wrap around the year
      return currentOrdinal >= startOrdinal && currentOrdinal <= endOrdinal;
    } else {
      // Range wraps around the year
      return currentOrdinal >= startOrdinal || currentOrdinal <= endOrdinal;
    }
  } catch (error) {
    console.error(error.message);
    return false;
  }
};

/**
 * Converts a Coptic date to a linear ordinal value.
 * Assumes months 1-12 have 30 days each, and month 13 has 5 days.
 * Adjust for leap years if necessary.
 *
 * @param {Object} date - The Coptic date object.
 * @param {number} date.month - The month (1-13).
 * @param {number} date.day - The day (1-30 or 1-5/6 for month 13).
 * @param {boolean} isLeapYear - Indicates if it's a leap year (for month 13).
 * @returns {number} The ordinal value.
 */
const toOrdinal = (date, isLeapYear = false) => {
  const { month, day } = date;

  // Validate month
  if (month < 1 || month > 13) {
    throw new Error(`Invalid month: ${month}. Must be between 1 and 13.`);
  }

  // Validate day
  if (month === 13) {
    const maxDay = isLeapYear ? 6 : 5;
    if (day < 1 || day > maxDay) {
      throw new Error(`Invalid day: ${day} for month ${month}. Must be between 1 and ${maxDay}.`);
    }
  } else {
    if (day < 1 || day > 30) {
      throw new Error(`Invalid day: ${day} for month ${month}. Must be between 1 and 30.`);
    }
  }

  // Calculate ordinal
  return (month - 1) * 30 + day;
};

export function addDaysToCopticDate(copticDate, days) {
  const monthLengths = [30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 5];

  let y = Number(copticDate.y);
  let m = Number(copticDate.m);
  let d = Number(copticDate.d);

  const isLeapYear = (year) => year % 4 === 3;

  if (days > 0) {
    while (days > 0) {
      if (d > 1) {
        const daysToSubtract = Math.min(d - 1, days);
        d -= daysToSubtract;
        days -= daysToSubtract;
      } else {
        m--;
        if (m < 1) {
          m = 13;
          y--;
        }
        d = m === 13 ? (isLeapYear(y) ? 6 : 5) : 30;
        days--;
      }
    }
  } else {
    days = Math.abs(days);
    while (days > 0) {
      const daysInMonth = m === 13 ? (isLeapYear(y) ? 6 : 5) : 30;
      const daysLeftInMonth = daysInMonth - d + 1;

      if (days >= daysLeftInMonth) {
        days -= daysLeftInMonth;
        m++;
        d = 1;
        if (m > 13) {
          m = 1;
          y++;
        }
      } else {
        d += days;
        days = 0;
      }
    }
  }

  return { y, m, d };
}

export function copticToGregorian(date) {
  // Convert values to numbers
  const copticDate = {
    m: Number(date.m),
    d: Number(date.d),
    y: Number(date.y),
  };

  // Validate input
  if (isNaN(copticDate.m) || isNaN(copticDate.d) || isNaN(copticDate.y)) {
    throw new Error("Invalid date values: all values must be convertible to numbers");
  }

  // Validate month and day
  if (copticDate.m < 1 || copticDate.m > 13) {
    throw new Error("Invalid Coptic month");
  }

  const isLastMonth = copticDate.m === 13;
  const maxDays = isLastMonth ? (isLeapYear(copticDate.y) ? 6 : 5) : 30;

  if (copticDate.d < 1 || copticDate.d > maxDays) {
    throw new Error("Invalid day for given month");
  }

  // Calculate the number of days since the Coptic epoch (August 29, 284 CE)
  let totalDays = copticDate.y * 365;
  totalDays += Math.floor((copticDate.y + 3) / 4); // Add leap days
  totalDays += (copticDate.m - 1) * 30; // Add days in current month
  totalDays += copticDate.d - 1; // Add days within current month

  // Convert to JavaScript Date (epoch + days)
  const gregorianDate = new Date(283, 8 - 1, 29); // Start from 283 CE instead of 284 CE
  gregorianDate.setDate(gregorianDate.getDate() + totalDays);

  return gregorianDate;
}

/**
 * Compares two Coptic dates
 * @param {Object} date1 - First Coptic date {y: year, m: month, d: day}
 * @param {Object} date2 - Second Coptic date {y: year, m: month, d: day}
 * @returns {number} - Returns -1 if date1 is earlier, 1 if date1 is later, 0 if equal
 */
export function compareCopticDates(date1, date2) {
  // Convert values to numbers
  date1 = {
    m: Number(date1.m),
    d: Number(date1.d),
    y: Number(date1.y),
  };

  date2 = {
    m: Number(date2.m),
    d: Number(date2.d),
    y: Number(date2.y),
  };

  if (date1.y !== date2.y) {
    return date1.y - date2.y;
  }
  if (date1.m !== date2.m) {
    return date1.m - date2.m;
  }
  return date1.d - date2.d;
}

/**
 * n-th weekday of given coptic day
 */
export function nthWeekdayOfCopticMonth(copticDay) {
  const day = copticDay / 7;
  const result = Math.round(day);
  let nthWeekday = 0;
  if (day == 0) {
    nthWeekday = 1;
  } else if (result >= day) {
    nthWeekday = result;
  } else {
    nthWeekday = result + 1;
  }
  return nthWeekday;
}

export function getWeekDayOfCopticDate(copDate) {
  return copticToGregorian(copDate).getDay();
}
