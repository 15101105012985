<script>
export default {
  name: "VarusTableOfPrayers",
};
</script>
<script setup>
import { watch } from "vue";
import { IonList } from "@ionic/vue";
import { useRoute } from "vue-router";
import { useScrollStore } from "../stores/scrollStore.js";
import VarusSideMenuLink from "../components/VarusSideMenuLink.vue";
import { useBookStore } from "../stores/bookStore";

const bookStore = useBookStore();
const scrollStore = useScrollStore();

const synchronizeCurrentPrayerWithChildMenu = async (id) => {
  const menuItem = document.getElementById(`menu-item-${id}`);
  if (menuItem) {
    // Find the Scrollable Container:
    // Ionic components use Shadow DOM internally. To access the scrollable container inside ion-content, the code queries its shadowRoot.
    const scrollContainer = menuItem.closest("ion-content")?.shadowRoot?.querySelector(".inner-scroll");

    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: menuItem.offsetTop - 50,
        behavior: "smooth",
      });
    }
  }
};

watch(
  () => scrollStore.scrollElementMain,
  (newId) => {
    if (newId) {
      synchronizeCurrentPrayerWithChildMenu(newId);
    }
  }
);
</script>

<template>
  <ion-list lines="full">
    <span v-if="useRoute().meta.showRightMenu">
      <template v-for="child in bookStore.prayers" :key="child.key">
        <router-link
          :to="useRoute().path + '#' + child.key"
          class="noline"
          :class="child.disabled ? 'linkDisabled' : 'linkEnabled'"
          replace
        >
          <div :id="'menu-item-' + child.key">
            <VarusSideMenuLink
              :element="child"
              sideMenuPosition="right"
              :isSelectedElement="scrollStore.scrollElementMain === child.key"
            />
          </div>
        </router-link>
      </template>
    </span>
  </ion-list>
</template>
